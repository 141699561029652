<template>
  <div>
    <div class="title" v-if="flag == 1">
      <!-- 查询 -->
      <el-row :gutter="3" style="padding: 10px 0 0 10px">
        <el-col :span="3">
          <el-input size="small" @input="(e) => (input = validForbid(e))" :placeholder="$t('AppManage.ApplicationCategory.placeholder')" v-model="input" @keyup.enter.native="onSbumit"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" style="height: 31px" size="small" @click="onSbumit">{{$t('public.Inquire')}}</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="3" style="padding: 10px 0 0 10px">
        <el-button-group>
          <el-button type="primary" size="small" @click="add">{{$t('public.New')}}</el-button>
          <el-button style="border-radius: 0px 3px 3px 0px;margin: 0px 0px 0px 0px;margin-left: 1px;"  @click="delMuch(delarr)" :type="typeInfo" :disabled="!hasSeleted" size="small">{{$t('public.Delete')}}</el-button>
        </el-button-group>
      </el-row>
      <el-row :gutter="16" class="mt16" style="margin-right: 10px; margin-left: 10px">
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" size="mini" :row-key="getRowKeys" stripe ref="multipleTable" :data="tableData" class="tableClass" tooltip-effect="dark" style="width: 100%; margin-top: 10px" @selection-change="handleSelectionChange" :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }" @sort-change="sort" :sort-orders="['descending', 'ascending']">
          <el-table-column :reserve-selection="true" header-align="center" align="center" type="selection" width="60" :selectable="selectEnable">
          </el-table-column>
          <el-table-column prop="name" sortable="custom" :show-overflow-tooltip="true" :label="$t('AppManage.ApplicationCategory.appList.Apps')" width="150">
            <template slot-scope="scope">
              <div>
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="memo" type="textarea" :rows="4" :show-overflow-tooltip="true" :label="$t('AppManage.ApplicationCategory.appList.Description')" width="150">
          </el-table-column>
          <!-- sortable 排序隐藏 -->
          <!-- <el-table-column prop="RELATION_NUMBER" label="关联应用" width="150">
            <template v-slot="{ row }">
              <span
                v-if="row.appCount <= 0"
                @click="nameKuang(row)"
                style="color: #606266"
              >
                {{ row.appCount }}
              </span>
              <span
                v-else
                @click="nameKuang(row)"
                style="color: #d38a08; cursor: pointer"
              >
                {{ row.appCount }}
              </span>
            </template>
          </el-table-column> -->
          <el-table-column sortable="custom" prop="lastUpdateTime" :label="$t('public.ModificationTime')" :show-overflow-tooltip="true" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
                {{ scope.row.lastUpdateTime | dateformat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <span class="action_icon">
              <img @click="nameKuang(row)" src="@/assets/icon_see.png" style="width: 15px; height: 15px; cursor: pointer;" :title="$t('AppManage.ApplicationCategory.appList.AssociatedApplication')" />
              </span>
              <span class="action_icon">
              <img @click="edit(row)" src="../../assets/icon_write.png" alt="" style="width: 15px; height: 15px; cursor: :pointer" :title="$t('public.Editor')" />
              </span>
              <span class="action_icon"><img v-show="row.name != '社交聊天' &&
                row.name !='办公商务' && row.id != 1" @click="deletes(row)" src="../../assets/icon_del.png" alt="" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " :title="$t('public.Delete')" />
                </span>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <pagination :page="listData.currentPage" :limit="listData.pageSize" :total="listData.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
        </div>
      </el-row>
    </div>
    <!-- 编辑的框 -->
    <div v-if="flag == 2">
      <div style="width: 500px; height: 800px; margin-top: 25px; margin-left: 60px">
          <el-form size="small" :model="ruleFormEdit" :rules="rulesEdit" ref="ruleFormEdit" label-width="120px" class="demo-ruleForm">
            <el-form-item :label="$t('AppManage.ApplicationCategory.addBtn.ClassName')" prop="name" style="padding: 0px">
              <el-input style="width: 250px" v-model="ruleFormEdit.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('AppManage.ApplicationCategory.addBtn.DeScript')">
              <el-input style="width: 250px" type="textarea" :rows="4" v-model="ruleFormEdit.memo" maxlength="200" show-word-limit></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="submitFormEdit('ruleFormEdit')">{{$t('public.Save')}}</el-button>
            </el-form-item>
          </el-form>
      </div> 
    </div>
    <!-- 新增的框 -->
    <div v-if="flag == 3">
       <div style="width: 500px; height: 800px; margin-top: 25px; margin-left: 60px">
          <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" @submit.native.prevent class="demo-ruleForm">
          <el-form-item :label="$t('AppManage.ApplicationCategory.addBtn.ClassName')" prop="name">
            <el-input style="width: 250px" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('AppManage.ApplicationCategory.addBtn.DeScript')" prop="memo">
            <el-input style="width: 250px" :rows="4" type="textarea" v-model="ruleForm.memo" maxlength="200" show-word-limit></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="submitForm('ruleForm')">{{$t('public.Save')}}</el-button>
          </el-form-item>
         </el-form>
       </div> 
    </div>
    <!-- 关联应用的弹框 -->
    <Eldialog :num="'470px'" :title="$t('AppManage.ApplicationCategory.AssociationBounce.relevanceTitle')" :dialogVisible="statusVisible" @handleClose="handleClose" @heightCustom="heightCustom">
      <el-input v-model="ipt" @input="(e) => (ipt = validForbid(e))" @keyup.enter.native="updateData" size="small" style="width: 180px; margin-bottom: 10px" :placeholder="$t('AppManage.ApplicationCategory.AssociationBounce.placeholder')">
        <i @click="updateData" slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer"></i>
      </el-input>
      <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" size="mini" stripe ref="multipleTable" :data="tableDataNmae" class="tableClass" tooltip-effect="dark" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column :show-overflow-tooltip="true" prop="appName" :label="$t('AppManage.ApplicationCategory.AssociationBounce.relevancePopUpList.ApplicationName')" width="150">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="packageName" :label="$t('AppManage.ApplicationCategory.AssociationBounce.relevancePopUpList.AppID')" width="150">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="versionName" :label="$t('public.Version')" width="150">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="osTypeId" :label="$t('public.OperatingSystem')">
          <template v-slot="{ row }">
            <template v-if="row.osTypeId == 20"> Android </template>
            <template v-if="row.osTypeId == 10"> iOS </template>
            <template v-if="row.osTypeId == 30"> Windows </template>
            <template v-if="row.osTypeId == 40"> Linux </template>
          </template>
        </el-table-column>
      </el-table>
      <p style="margin-top: 10px">
        {{$t('public.total')}}<b>{{ this.tableDataNmae.length }}</b> {{$t('public.recordss')}}
      </p>
    </Eldialog>
    <!-- 提示消息框 -->
    <dialogInfo :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import naviGation from '@/components/hearder/index' //头部组件
import Eldialog from '@/components/elDialog' //详情弹框
import pagination from '@/components/pagination/page.vue' //分页
import dialogInfo from '@/components/promptMessage/index' //提示消息框
export default {
  components: {
    naviGation,
    Eldialog,
    pagination,
    dialogInfo
  },
  data() {
    let validcodeName = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('不支持空格及特殊字符'))
      } else {
        callback()
      }
    }
    return {
      typeInfo: 'info',// 删除按钮默认灰色
      id: '',
      delarr: [], //多个删除存放数组
      flag: 1, //显示隐藏切换
      delarr: [], //存放要删除的数据
      statusVisible: false, //关联应用弹框默认不显示
      visibaelFlag: false, //提示消息弹框默认不显示
      input: '', //关联应用弹框的查询
      ipt: '', //点击名称里面的查询
      dataTolCount: '', //点击列表的总数
      titleInfo: '', 
      flagDisable: false,// 遮罩全选
      isCheckShow: false,
      titleInfo: '', 
      listData: {// 列表参数
        total: 0,
        manage: '1',
        currentPage: 1,
        pageSize: 10,
        orderColume: 'lastUpdateTime',
        appCategory: '',
        orderRule: 'DESC'
      }, 
      tableData: [],// 数据列表 
      tableDataNmae: [], // 点击名称的列表
      nameData: {
        currentPage: 1, //pageNumber
        pageSize: 9999, //rowNumber
        id: '',
        orderRule: 'DESC',
        orderColume: 'UPDATE_TIME'
      },
      page: {// 分页
        orderColume: 'UPDATE_TIME',
        orderRule: 'ASC',
        pageSize: 10, //每页条数,  默认10条
        total: 0, //总条数
        // totalPages: 40, //总页数
        currentPage: 1
      },
      ruleForm: {//新增参数
        name: '',
        memo: ''
      },
      
      rules: {// 新增校验
        name: [
          {
            required: true,
            message: '请输入类别名称',
            trigger: 'blur'
          },

          { min: 1, max: 8, message: '最长输入 8 个字符', trigger: 'blur' },
          { validator: validcodeName, trigger: 'blur' }
        ]
      },
      ruleFormEdit: {//编辑参数
        name: '',
        memo: ''
      },
      
      rulesEdit: {// 编辑校验
        name: [
          {
            required: true,
            message: '请输入类别名称',
            trigger: 'blur'
          },
          { min: 1, max: 8, message: '最长输入 8 个字符', trigger: 'blur' },
          { validator: validcodeName, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length
    }
  },
  mounted() {
    this.tableList()
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    // 禁用
    selectEnable(row, rowIndex) {
      if (
        row.name == '社交聊天' ||
        row.name == '办公商务' ||
        row.id == 1
      ) {
        return false
      } else {
        return true
      }
    },
    iRowStyle: function ({ row, rowIndex }) {//表格行高
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:3px 0px'
    },
    
    async tableList() {// 列表
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: this.listData.pageSize,
        appCategory: this.input,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule
      }
      const res = await this.$axios.post(
        '/httpServe/entAppCategory/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content //列表数据
      this.listData.total = res.data.total //列表总数
    },
    
    async addListData() {// 新增接口
      var param = {
        memo: this.ruleForm.memo,
        name: this.ruleForm.name
      }
      const res = await this.$axios.post(
        '/httpServe/entAppCategory/save',
        param,
        true
      )
      if (res.data == -1) {
        this.$message({
          message: this.$t('AppManage.ApplicationCategory.addBtn.saveError'),
          type: 'error',
          offset: 100
        })
        this.flag = 3
      } else { 
        this.$refs.ruleForm.resetFields()
        this.input = '' 
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
        this.listData.currentPage = 1
        this.tableList()
        this.flag = 1
      }
    },
    
    add() {// 新增
      this.flag = 3
    },
   
    submitForm(formName) { // 新增校验 
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addListData() 
        } else { 
          return false
        }
      })
    },
    
    edit(val) {// 编辑
      this.flag = 2
      this.ruleFormEdit = val
    },
    
    async save() {// 编辑保存
      var param = {
        ids: [this.ruleFormEdit.id],
        memo: this.ruleFormEdit.memo,
        name: this.ruleFormEdit.name
      } 
      const res = await this.$axios.post(
        '/httpServe/entAppCategory/update',
        param,
        true
      )
      if (res.data == -1) { 
        this.$message({
          message: this.$t('AppManage.ApplicationCategory.addBtn.saveError'),
          type: 'error',
          offset: 100
        })
        this.flag = 2
      } else { 
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
        this.flag = 1
        this.input = ''
        this.listData.currentPage = 1
        this.tableList()
      }
    },
    // 编辑校验
    submitFormEdit(ruleFormEdit) {
      this.$refs[ruleFormEdit].validate((valid) => {
        if (valid) {
          this.save()
        } else { 
          return false
        }
      })
    },
    deletes(val) {
      this.$confirm(this.$t('AppManage.ApplicationCategory.addBtn.delLoses'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(() => {
          this.del(val)
        })
        .catch(() => {
          return false
        })
    },
    // 删除
    async del(row) {
      var param = {
        ids: [row.id],
        deleteAll: 2
      }
      const res = await this.$axios.post(
        '/httpServe/entAppCategory/delete',
        param,
        true
      )
      if (res.data == -1) { 
        this.$message({
          message:this.$t('AppManage.ApplicationCategory.addBtn.saveErrorMessage'),
          type: 'error',
          offset: 100
        })
      } else { 
        this.$message({
          message: this.$t('public.SuccessfullyDeleted'),
          type: 'success',
          offset: 100
        })
        this.listData.currentPage='1'
        this.$refs.multipleTable.clearSelection()
        this.listData.currentPage = 1
        this.tableList()
      }
    },
    // 查询 onSbumit
    async onSbumit() {
      if (this.input.length > 0) {
        console.log(this.input.length, '--')
        this.listData.currentPage = 1
      }
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: this.listData.pageSize,
        appCategory: this.input,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule
      }
      const res = await this.$axios.post(
        '/httpServe/entAppCategory/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content //列表数据
      this.listData.total = res.data.total //列表总数 
    },
    //多条删除
    async delMuch(obj) {
      this.$confirm(this.$t('AppManage.ApplicationCategory.addBtn.delLoses'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          var ids = []
          obj.forEach((item) => {
            ids.push(item.id)
          })
          var param = {
            ids: ids,
            deleteAll: 2
          }
          const res = await this.$axios.post(
            '/httpServe/entAppCategory/delete',
            param,
            true
          )
          if (res.data == -1) { 
            this.$message({
              message:this.$t('AppManage.ApplicationCategory.addBtn.saveErrorMessage'),
              type: 'error',
              offset: 100
            })
          } else { 
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            })
            this.listData.currentPage='1'
            this.$refs.multipleTable.clearSelection()
            this.listData.currentPage = 1
            this.tableList()
          } 
        })
        .catch(() => {
          return false
        })
    },
    
    handleSelectionChange(obj) {//表格当删除发生变化
      this.delarr = obj
      this.typeInfo = 'primary'
      if (this.delarr.length == 0) {
        this.typeInfo = 'info'
      }
    },
    
    checkChange(selection, row) {//当用户手动勾选数据行的 Checkbox 时触发的事件
      let arr1 = []
      selection.forEach((item) => {
        arr1.push(item.id)
        //去重
        this.delarr = [...new Set(arr1)]
      })
    },
    
    handleParentGetList(page, limit) {// 响应分页组件查询事件
      this.listData.currentPage = page
      this.listData.pageSize = limit 
      this.tableList()// 调用列表
    },
    
    sort(column) {// 排序
      if (column.prop == 'name') {
        if (column.order == 'ascending') {
          this.listData.orderColume = 'name'
          this.listData.orderRule = 'ASC'
        } else {
          this.listData.orderColume = 'name'
          this.listData.orderRule = 'DESC'
        }
      } else if (column.prop == 'lastUpdateTime') {
        if (column.order == 'ascending') {
          this.listData.orderColume = 'lastUpdateTime'
          this.listData.orderRule = 'ASC'
        } else {
          this.listData.orderColume = 'lastUpdateTime'
          this.listData.orderRule = 'DESC'
        }
      }
      this.tableList()
    },
    
    heightCustom() {// 高度
      this.height.height = this.num
    },
    
    async nameKuang(val) {// 点击名称详情
      this.id = val.id 
      this.statusVisible = true
      var param = {
        id: val.id,
        currentPage: this.nameData.currentPage,
        pageSize: this.nameData.pageSize
      }
      const res = await this.$axios.post(
        '/httpServe/entAppCategory/appLinks',
        param,
        true
      )
      this.tableDataNmae = res.data.content
      this.dataTolCount = res.data.total
      var data = [] 
    },

    async updateData() {
      var param = {
        id: this.id,
        currentPage: this.nameData.currentPage,
        pageSize: this.nameData.pageSize,
        name: this.ipt
      }
      const res = await this.$axios.post(
        '/httpServe/entAppCategory/appLinks',
        param,
        true
      )
      
      this.tableDataNmae = res.data.content 
    },
    handleClose() {// 弹框点击关闭事件
      this.statusVisible = false //点击出现详情弹框
      this.visibaelFlag = false //提示消息弹框关闭
      this.ipt = '' //关联应用清空条件
    },
    
    maskLayer() {//全选遮罩层
      this.isCheckShow = true
    },
    
    leave() {// 鼠标移出弹框
      this.isCheckShow = false
    },
    curSelection() {//当前页全选
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    determine() { //确定的关闭
      this.visibaelFlag = false
    },
    
    toggleSelection(rows) {//取消全选
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
        this.disabled = false
      }
      this.isCheckShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 47px;
  left: 10px;
  z-index: 999;
  // background:rgba(255, 255, 255,.1)
  li {
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;
    div {
      display: inline-block;
    }
  }
  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}
// 处理按钮
.btn {
  width: 100%;
  margin-top: 10px;
  .el-button {
    // color: rgb(76, 184, 247);
    font-weight: 900;
  }
}
.el-form-item__label {
  padding: 0 0 0 0 !important;
}
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
::v-deep .el-textarea__inner {
  font-family: 'Microsoft YaHei';
}
::v-deep .el-textarea__inner::placeholder {
  font-family: 'Microsoft YaHei';
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
